<template>
  <div id="fileUpload">
    <el-upload
      :action="action"
      :headers="{ ['Authorization']: 'Bearer ' + token }"
      :show-file-list="false"
      :data="data"
      :before-upload="
        () => {
          loading = true;
        }
      "
      :on-error="
        () => {
          loading = false;
        }
      "
      :on-success="handleSuccess"
    >
      <el-button size="small" type="primary" :loading="loading">{{
        title
      }}</el-button>
    </el-upload>
    <el-link
      class="previewUrl"
      v-if="previewUrl"
      :href="previewUrl"
      target="_blank"
      >{{ previewUrl }}</el-link
    >
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    previewUrl: {
      type: String,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    title: {
      type: String,
      default: "点击上传",
    },
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    action() {
      return `${process.env.VUE_APP_BASE_URL}${this.url}`;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleSuccess(res) {
      if (res.code === 0) {
        this.$emit("success", res.data);
      } else {
        this.$message.error(res.msg);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
#fileUpload {
  .el-upload {
  }
  .previewUrl {
    width: 100%;
    display: inline-block;
    @include overflow-ellipsis;
  }
}
</style>
